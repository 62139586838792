<template>
	<div id="assignList" v-loading="loading" :element-loading-text="loadingText"
		element-loading-background="rgba(0, 0, 0, 0)">
		<!-- 查询 -->
		<div class="search">
			<el-row :gutter="10">
				<el-col :xs="8" :sm="8">
					<el-input v-model="keyword" placeholder="请输入参考员工姓名" @input="keywordInput(keyword)"></el-input>
				</el-col>
				<el-col :xs="16" :sm="16" class="searchStyle">
					<el-button size="small" class="searchButton" @click="searchClick">查询</el-button>
					<el-button size="small" type="warning" @click="addAssign">设定参考人员</el-button>
					<el-button size="small" type="danger" v-show="assignData.length && !buttonShow" @click="buttonClick('delete')">移除参考人员
					</el-button>
					<el-button size="small" type="warning" v-show="assignData.length && !buttonShow" @click="buttonClick('remove')">回收试卷
					</el-button>
					<el-button size="small" type="primary" v-show="assignData.length && !buttonShow" @click="buttonClick('send')">下发试卷</el-button>
					<el-button size="small" type="primary" v-show="buttonShow" @click="sureClick">确定{{operaValue}}</el-button>
					<el-button size="small" type="infor" v-show="buttonShow" @click="cancelClick">取消{{operaValue}}</el-button>
				</el-col>
			</el-row>
		</div>
		<el-table :cell-style="rowClass" ref="multipleTable" :header-cell-style="headClass" :data="assignData" border
			stripe height="600" @selection-change="handleSelectionChange">
			<template slot="empty">
				<span v-show="loading">加载中...</span>
				<span v-show="!loading">暂无数据</span>
			</template>
			<el-table-column v-if="buttonShow" :selectable="checkSelect" type="selection" width="55">
			</el-table-column>
			<el-table-column prop="employeeName" label="员工姓名" :show-overflow-tooltip="true">
				<template scope="scope">
					{{scope.row.employeeName || '--'}}
				</template>
			</el-table-column>
			<el-table-column prop="employeeCode" label="员工工号" :show-overflow-tooltip="true">
				<template scope="scope">
					{{scope.row.employeeCode || '--'}}
				</template>
			</el-table-column>
			<el-table-column prop="hasExam" label="已有试卷" :show-overflow-tooltip="true">
				<template scope="scope">
					{{scope.row.hasExam?'是':'否'}}
				</template>
			</el-table-column>
			<el-table-column prop="startTs" label="开考时间" :show-overflow-tooltip="true">
				<template scope="scope">
					{{scope.row.startTs || '--'}}
				</template>
			</el-table-column>
			<el-table-column prop="endTs" label="提交时间" :show-overflow-tooltip="true">
				<template scope="scope">
					{{scope.row.endTs || '--'}}
				</template>
			</el-table-column>
			<el-table-column prop="allScore" label="分数" :show-overflow-tooltip="true">
				<template scope="scope">
					<span v-if="scope.row.allScore || scope.row.allScore===0">{{scope.row.allScore}}</span>
					<span v-else>--</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="120">
				<template slot-scope="scope">
					<span v-if="scope.row.score || scope.row.score===0">
						<el-button type="text" @click="detailClick(scope.$index,scope.row)">
							答题详情</el-button>
					</span>
					<span v-else>
						<el-button type="text" :disabled="true">答题详情</el-button>
					</span>
				</template>
			</el-table-column>
		</el-table>
		<!-- 设定参考人员多选对话框 -->
		<el-dialog title="设定参考人员" :visible.sync="dialogVisible" class="assignDialog" :close-on-click-modal="false"
			append-to-body>
			<el-cascader placeholder="请选择参考人员" v-model="value" :options="departmentData" :props="optionProps"
				collapse-tags filterable clearable @change="handleChange">
			</el-cascader>
			<div slot="footer" class="dialog-footer">
				<el-button class="btnGreen" size="small" type="primary" @click="confirmClick">确 定</el-button>
				<el-button class="cancelBtn" size="small" @click="dialogVisible = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- 答题弹框 -->
		<el-dialog title="答题框" :visible.sync="dialogForm" :close-on-click-modal="false" class="detailDialog"
			append-to-body>
			<exam-form ref="examForm" v-on:subformBtn='getFormValue($event)'></exam-form>
		</el-dialog>
	</div>
</template>

<script>
	import Form from '../client/form.vue';
	export default {
		name: 'AssignList',
		components: {
			"exam-form": Form,
		},
		data() {
			return {
				// 加载中
				loading: false, //加载中
				loadingText: "",
				// data数据
				assignData: [],
				id: "", //试卷id
				// 查询
				keyword: "",
				keywordValue: "",
				// 设定参考人员多选对话框
				dialogVisible: false,
				departmentData: [],
				// 选中人员的id
				value: [],
				userIds: [],
				optionProps: {
					multiple: true,
					value: 'id',
					label: 'name',
					children: 'children'
				},
				// 复选
				deleteSelection: [],
				// 答题详情
				dialogForm: false,
				// 确定和取消的显示
				buttonShow:false,
				// 执行操作
				urlValue:'',
				operaValue:''
			}
		},
		mounted() {
			var that = this;
		},
		methods: {
			checkSelect(row, index) {
				let isChecked = true;
				if(this.urlValue=='delete'){
					isChecked = true;
				}else if(this.urlValue=='remove'){
					if (row.hasExam) {
						isChecked = true
					} else {
						isChecked = false
					}
				}else{
					if (!row.hasExam) {
						isChecked = true
					} else {
						isChecked = false
					}
				}
				return isChecked
			},
			// 关闭弹框
			getFormValue(val) {
				console.log(val);
				this.dialogForm = false;
			},
			// 答题详情
			detailClick(index, row) {
				var that = this;
				that.dialogForm = true;
				row.categoryId = that.id;
				setTimeout(function() {
					that.$refs.examForm.getDetail(row, row.userId, 'second');
				}, 10)
			},
			// 查询时关键字不能为空
			keywordInput(val) {
				this.keyword = val.replace(/\s+/g, "");
			},
			// 获取公司员工组织结构图
			getDepartment() {
				let self = this;
				self.$axios.get("/hr/employee/list/byDepartment")
					.then(function(response) {
						var arr = JSON.stringify(response.data);
						var array = JSON.parse(arr);
						self.departmentData = array;
					}).catch(function(error) {
						self.departmentData = [];
					});
			},
			// 关闭弹框重置data
			initData() {
				Object.assign(this.$data, this.$options.data())
			},
			//表格样式
			headClass() {
				return "text-align: center;background:#D7A24A; color:#ffffff";
			},
			rowClass() {
				return "text-align: center;";
			},
			// 点击查询
			searchClick() {
				var that = this;
				that.keywordValue = that.keyword;
				that.getAssignData(that.id);
			},
			// 参考人员选择
			handleSelectionChange(val) {
				var that = this;
				that.deleteSelection = [];
				val.forEach(item => {
					that.deleteSelection.push(item.userId);
				})
			},
			// 点击
			buttonClick(val){
				this.buttonShow=true;
				this.urlValue=val;
				if(this.urlValue=='delete'){
					this.operaValue='移除参考人员'
				}else if(this.urlValue=='remove'){
					this.operaValue='回收试卷'
				}else{
					this.operaValue='下发试卷'
				}
			},
			// 确定删除参考人员
			deleteassign() {
				var that = this;
				if (that.deleteSelection.length > 0) {
					that.$confirm('移除该人员会同时移除该人员的试卷，确定要移除吗？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						that.$axios.put("/category/assign/remove", {
								categoryId: that.id,
								userIds: that.deleteSelection
							})
							.then(res => {
								that.$notify({
									title: '提示',
									message: '删除成功!',
									type: 'success',
									duration: 2000
								});
								that.cancelClick();
								that.getAssignData(that.id);
							})
							.catch(err => {
								that.$notify({
									title: '失败',
									message: err,
									type: 'error',
									duration: 2000
								});
							})
					}).catch(() => {});
				} else {
					that.$notify({
						title: '警告',
						message: "请选中需要移除的员工",
						type: 'warning',
						duration: 2000
					});
				}
			},
			// 回收试卷
			removeExam() {
				var that = this;
				if (that.deleteSelection.length > 0) {
					that.$confirm('确定要回收此参考人员的试卷吗？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						that.$axios.post("/category/assign/removeExam", {
								categoryId: that.id,
								userIds: that.deleteSelection
							})
							.then(res => {
								that.$notify({
									title: '提示',
									message: '回收试卷成功!',
									type: 'success',
									duration: 2000
								});
								that.cancelClick();
								that.getAssignData(that.id);
							})
							.catch(err => {
								that.$notify({
									title: '失败',
									message: err,
									type: 'error',
									duration: 2000
								});
							})
					}).catch(() => {});
				} else {
					that.$notify({
						title: '警告',
						message: "请选中需要回收试卷的员工",
						type: 'warning',
					});
				}
			},
			// 下发试卷
			sendExam() {
				var that = this;
				if (that.deleteSelection.length > 0) {
					that.$confirm('确定要下发此参考人员试卷吗？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						that.$axios.post("/category/assign/sendExam", {
								categoryId: that.id,
								userIds: that.deleteSelection
							})
							.then(res => {
								that.$notify({
									title: '提示',
									message: '下发试卷成功!',
									type: 'success',
									duration: 2000
								});
								that.cancelClick();
								that.getAssignData(that.id);
							})
							.catch(err => {
								that.$notify({
									title: '失败',
									message: err,
									type: 'error',
									duration: 2000
								});
							})
					}).catch(() => {});
				} else {
					that.$notify({
						title: '警告',
						message: "请选中需要下发试卷的员工",
						type: 'warning',
						duration: 2000
					});
				}
			},
			// 点击添加参考人员
			addAssign() {
				var that = this;
				that.dialogVisible = true;
				that.getDepartment();
			},
			// 获取参考人员列表
			getAssignData(val) {
				var that = this;
				that.loading = true;
				that.id = val; //试卷id赋值
				that.$axios.post("/category/assign/list", {
						categoryId: val,
						keyword: that.keywordValue
					})
					.then(res => {
						that.assignData = res.data;
						that.assignData.forEach(item => {
							item.allScore=Number(item.score?item.score:0)+Number(item.scoreExtra?item.scoreExtra:0);
							if (item.startTs) {
								item.startTs = that.COMMON.getDataMethods('getTime', new Date(item.startTs));
							}
							if (item.endTs) {
								item.endTs = that.COMMON.getDataMethods('getTime', new Date(item.endTs));
							}
						});
						console.log(that.assignData);
						that.loading = false;
					})
					.catch(err => {
						that.$notify({
							title: '失败',
							message: err,
							type: 'error',
							duration: 2000
						});
						that.loading = false;
					})
			},
			// 选择需要添加的参考人员
			handleChange(value) {
				var that = this;
				var arr = JSON.stringify(that.departmentData);
				var array = JSON.parse(arr);
				that.userIds = [];
				value.forEach(item => {
					// 获取id进行判断是部门还是员工
					var idnode = that.dep(array, item[item.length - 1]);
					if (idnode.employee) {
						that.userIds.push(item[item.length - 1]);
					}
				});
			},
			dep(node, value) {
				var that = this;
				if (node.id == value) return node;
				for (var i = 0; i < node.length; i++) {
					if (node[i]) {
						if (node[i].children) {
							var val = that.dep(node[i].children, value);
							if (val) return val;
						} else {
							var val = that.dep(node[i], value);
							if (val) return val;
						}
					}
				}
				return null;
			},
			// 确定添加参考人员
			confirmClick(index, row) {
				var that = this;
				var url = "/category/assign/add";
				var postData = {
					categoryId: that.id,
					userIds: that.userIds
				};
				that.$axios.post(url, postData)
					.then(res => {
						that.getAssignData(that.id);
						that.$notify({
							title: '提示',
							message: '参考人员设定成功!',
							type: 'success',
							duration: 2000
						});
						that.value = [];
						that.userIds = [];
						that.dialogVisible = false;
					})
					.catch(err => {
						that.$notify({
							title: '失败',
							message: err,
							type: 'error',
							duration: 2000
						});
					})
			},
			// 确定
			sureClick(){
				if(this.urlValue=='delete'){
					this.deleteassign();
				}else if(this.urlValue=='remove'){
					this.removeExam();
				}else{
					this.sendExam();
				}
			},
			// 取消
			cancelClick() {
				this.buttonShow=false;
				this.urlValue='';
				this.operaValue='';
				this.$refs.multipleTable.clearSelection();
				this.deleteSelection = [];
			}
		}
	}
</script>

<style lang='scss' scoped>
	#assignList {
		.search {
			margin-bottom: 10px;

			.el-button {
				margin-top: 5px;
			}

			.searchButton {
				background: #000000;
				color: #FFFFFF;
			}
		}
	}
</style>
